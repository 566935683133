import * as React from "react";
import {makeStyles} from "@material-ui/core/styles";
import {DateField, DateInput, FileInput, FormDataConsumer, FormTab, FunctionField, NumberInput, ReferenceField, ReferenceInput, required, SelectInput, TabbedForm, TextField, TextInput, useRedirect, useTranslate} from "react-admin";
import {hasRole, ROLE_ADMIN, ROLE_DEALER} from "../../../utils/AuthUtils";
import EditToolbar from "./EditToolbar";
import {Typography} from "@material-ui/core";
import { useHistory } from 'react-router-dom';
import CustomFileField from "../../../fields/CustomFileField";
import TransitionLog from "./TransitionLog";
import {useEffect, useState} from "react";
import Button from "@material-ui/core/Button";
import { parsePath } from 'history';

const useStyles = makeStyles(theme => ({
    first: {display: 'inline-block', minWidth: 256},
    second: {display: 'inline-block', marginLeft: 32, minWidth: 256},
    inLine: {display: 'inline-block'},
    double: {minWidth: 544},
    logo: {
        maxWidth: 80,
        margin: 20
    },
}));

export const EditForm = (props) => {

    const [canEdit, setCanEdit] = useState(false);

    const translate = useTranslate();
    const classes = useStyles();
    const history = useHistory();

    const {permissions} = props;

    const configureQuill = quill => {
        quill.enable(hasRole(ROLE_ADMIN, permissions));
    }

    useEffect(() => {
        if (hasRole(ROLE_ADMIN, permissions) || (hasRole(ROLE_DEALER, permissions) && props.record.isEditable)) {
            setCanEdit(true);
        }
    })

    const linkToTab = (basePath, id, tab) => {
        const path = tab > 0 ? `${basePath}/${encodeURIComponent(id)}/${tab}` : `${basePath}/${encodeURIComponent(id)}`
        history.push({
            ...parsePath(path)
        });
    }

    const nextTab = (tab) => {
        const nextTab = tab === 5 ? 0 : tab;
        linkToTab(props.basePath, props.record.id, nextTab);
    }

    return (
        <TabbedForm redirect="edit" toolbar={<EditToolbar canEdit={canEdit}/>} {...props}>
            <FormTab label="details">
                <ReferenceField addLabel={false} source="policy" reference="policies" link={false} formClassName={classes.second}>
                    <FunctionField
                        addLabel={false}
                        render={record => {
                            if (record.productName && record.productName.indexOf('TCS') > -1) {
                                return <img src='/TCS-Logo-oben-CMYK-1601.png' className={classes.logo} alt='' />
                            }

                            return <div/>
                        }}
                    />
                </ReferenceField>
                <Typography/>
                <ReferenceField label={translate("resources.policies.fields.vin")} source="policy" reference="policies" link={false} formClassName={classes.first}>
                    <TextField source="vin"/>
                </ReferenceField>

                <ReferenceField label={translate("resources.policies.fields.makeModelText")} source="policy" reference="policies" link={false} formClassName={classes.second}>
                    <TextField source="makeModelText"/>
                </ReferenceField>

                <Typography/>
                <ReferenceField label={translate("resources.policies.fields.firstRegistration")} source="policy" reference="policies" link={false} formClassName={classes.first}>
                    <DateField source="firstRegisterDate"/>
                </ReferenceField>
                <ReferenceField label={translate("resources.policies.fields.productName")} source="policy" reference="policies" link={false} formClassName={classes.second}>
                    <TextField source="productName"/>
                </ReferenceField>

                <Typography/>
                <ReferenceField label={translate("resources.policies.fields.productActivationDate")} source="policy" reference="policies" link={false} formClassName={classes.first}>
                    <DateField source="productActivationDate"/>
                </ReferenceField>
                <ReferenceField label={translate("resources.policies.fields.productWarrantyEnd")} source="policy" reference="policies" link={false} formClassName={classes.second}>
                    <DateField source="productWarrantyEnd"/>
                </ReferenceField>
                <ReferenceField label="Product creation date" source="policy" reference="policies" link={false} formClassName={classes.second}>
                    <DateField source="productCreationDate"/>
                </ReferenceField>

                <Typography variant="h6" gutterBottom fullWidth>{translate('resources.damage-reports.blocks.reporter')}</Typography>
                <TextInput source="dealer" className={classes.double} disabled={!hasRole(ROLE_ADMIN, permissions)}/>

                <Typography variant="h6" gutterBottom fullWidth>{translate('resources.damage-reports.blocks.contact_person')}</Typography>
                <TextInput source="contactFirstName" formClassName={classes.first} disabled={!canEdit}/>
                <TextInput source="contactLastName" formClassName={classes.second} disabled={!canEdit}/>
                <TextInput label="Email" source="contactEmail" disabled={!canEdit}/>

                <Typography variant="h6" gutterBottom fullWidth>{translate('resources.damage-reports.blocks.address')}</Typography>
                <TextInput source="street" validate={[required()]} formClassName={classes.first} disabled={!canEdit}/>
                <TextInput source="zip" validate={[required()]} formClassName={classes.second} disabled={!canEdit}/>
                <Typography/>
                <TextInput source="city" validate={[required()]} formClassName={classes.first} disabled={!canEdit}/>
                <ReferenceInput source="country" reference="countries" validate={[required()]} formClassName={classes.second} disabled={!canEdit}>
                    <SelectInput optionText="name"/>
                </ReferenceInput>

                <Typography variant="h6" gutterBottom fullWidth>{translate('resources.damage-reports.blocks.vehicle')}</Typography>
                <DateInput source="claimDate" validate={[required()]} formClassName={classes.first} disabled />
                <NumberInput source="mileage" validate={[required()]} formClassName={classes.second} disabled={!canEdit}/>

                <Typography variant="h6" gutterBottom fullWidth>{translate('resources.damage-reports.blocks.damage_report')}</Typography>
                <ReferenceInput disabled={!canEdit} source="currency" reference="currencies" validate={[required()]} formClassName={classes.first}>
                    <SelectInput optionText="name"/>
                </ReferenceInput>
                <FormDataConsumer source="vatRate" formClassName={classes.second}>
                    {({formData, ...rest}) => {
                        return (
                            <ReferenceInput source="vatRate" reference="vat-rates" validate={[required()]} formClassName={classes.second} filter={{country: formData.country}} sort={{field: "rate"}} disabled={!canEdit} {...rest}>
                                <SelectInput optionText={(record) => `${record.rate} %`}/>
                            </ReferenceInput>
                        );
                    }}
                </FormDataConsumer>

                <Typography/>
                <NumberInput source="labourCost" helperText="incl. VAT" formClassName={classes.first} disabled={!canEdit}/>
                <NumberInput source="partsCost" helperText="incl. VAT" formClassName={classes.second} disabled={!canEdit}/>
                <FormDataConsumer formClassName={classes.second}>
                    {({formData, ...rest}) => {
                        return <NumberInput InputProps={{value: (formData.labourCost ?? 0) + (formData.partsCost ?? 0)}} source="totalCost" disabled formClassName={classes.second} {...rest} />;
                    }}
                </FormDataConsumer>

                <Typography/>
                <Button  variant="contained"  color="primary" onClick={() => nextTab(1)}>Next</Button>
            </FormTab>
            <FormTab label="description">
                <TextInput source="damageDescription" multiline={true} fullWidth disabled={!canEdit}/>
                <TextInput source="diagnosis" multiline={true} fullWidth disabled={!canEdit}/>
                <TextInput source="repairDescription" multiline={true} fullWidth disabled={!canEdit}/>
                {
                    !hasRole(ROLE_DEALER, permissions) && <TextInput source="internalComment" multiline={true} fullWidth />
                }
                <Typography/>
                <Button  variant="contained"  color="primary" onClick={() => nextTab(2)}>Next</Button>
            </FormTab>
            <FormTab label="files">
                <FileInput source="costCalculationFile" validate={[required()]} maxSize={5000000} options={{disabled: !canEdit}}>
                    <CustomFileField source="src" title="title" target="_blank"/>
                </FileInput>

                <FileInput source="vehicleRegistrationFile" validate={[required()]} maxSize={5000000} options={{disabled: !canEdit}}>
                    <CustomFileField source="src" title="title" target="_blank"/>
                </FileInput>

                <FileInput source="serviceConfirmationFile" validate={[required()]} maxSize={5000000} options={{disabled: !canEdit}}>
                    <CustomFileField source="src" title="title" target="_blank"/>
                </FileInput>

                <FileInput source="tcsInspectionFile" maxSize={5000000} options={{disabled: !canEdit}}>
                    <CustomFileField source="src" title="title" target="_blank"/>
                </FileInput>

                <Typography/>
                <Button  variant="contained"  color="primary" onClick={() => nextTab(3)}>Next</Button>
            </FormTab>
            <FormTab label="invoice">
                <Button onClick={() => nextTab(4)}>Next</Button>
            </FormTab>
            <FormTab label="communication">
                <TransitionLog />
            </FormTab>
        </TabbedForm>
    )
}